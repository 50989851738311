<template>
  <div id="app" class="app">
    <SideBar v-if="showSidebar" />
    <div :class="{ 'main-wrapper': true, 'ps-0': !showSidebar }">
      <router-view />
    </div>
    <FogOverlay />
    <SidePanel />
    <Popup />
  </div>
</template>

<script lang="ts" src="./app.ts"></script>
<style lang="scss" src="./app.scss"></style>
