import {defineStore} from 'pinia';
import {ROLES, USER_ROLES} from "@/types";

interface IAppUser {
  id: string;
  lastName: string;
  firstName: string;
  roles: USER_ROLES[];
}

export interface IStateUser {
  user: IAppUser|null;
  token: string|null;
}

export const useUserStore = defineStore('user', {
  state: (): IStateUser => {
    return {
      user: null,
      token: null
    };
  },

  getters: {
    isGranted: (state: IStateUser) => (roles: USER_ROLES[] | USER_ROLES) => {
      roles = Array.isArray(roles) ? roles : [roles];
      return roles.length === 0 || (state.user && state.user.roles && state.user.roles.some((r: USER_ROLES) => roles.includes(r)));
    },

    getFullname: (state: IStateUser) => () => {
      return state.user ? `${state.user.firstName} ${state.user.lastName}` : null;
    },

    isExternalConsultantOnly() {
      return !this.isGranted(Object.values(ROLES).filter((r: ROLES) => r !== ROLES.EXTERNAL_CONSULTATION));
    }
  }
});
