import { defineStore } from 'pinia';
import { omit, sort } from '@/utils/object';

export enum ProjectsFilter {
  Author = 'and[author]',
  Type = 'and[typeAide]',
  Theme = 'and[thematique]',
  Title = 'and[or][][titre]',
  FunctionalId = 'and[or][][idFonctionnel]',
  State = 'and[statut]',
  OpeningDate = 'dateFin[after]',
  ClosingDate = 'dateFin[strictly_before]'
}

interface SearchPagination {
  page: number;
  itemsPerPage: number;
}

export interface IStateSearch {
  projects: SearchPagination & Record<ProjectsFilter, any>;
}

const defautStateProject = {
  itemsPerPage: 10,
  page: 1,
  [ProjectsFilter.Author]: null,
  [ProjectsFilter.Type]: [],
  [ProjectsFilter.Theme]: [],
  [ProjectsFilter.Title]: null,
  [ProjectsFilter.FunctionalId]: null,
  [ProjectsFilter.State]: []
};

export const useSearchStore = defineStore('search', {
  state: (): IStateSearch => {
    return {
      projects: { ...defautStateProject }
    } as IStateSearch;
  },
  getters: {
    getProjectFiltersAsString: (state) => {
      return (filters?: Record<string, any>) => JSON.stringify(sort(omit(JSON.parse(JSON.stringify(filters ?? state.projects)), ['page'])));
    },
    isDefaultSearch: (state) => {
      return JSON.stringify(state.projects) === JSON.stringify(defautStateProject);
    }
  },
  actions: {
    resetProjectsSearch() {
      this.projects = { ...defautStateProject };
    }
  }
});
