{
  "title": "Référentiel des aides ADEME",
  "add": "Ajouter",
  "added": "Ajouté",
  "remove": "Retirer",
  "back": "Retour",
  "cancel": "Annuler",
  "confirm": "Confirmer",
  "export": "Exporter",
  "editLocked": "Modification bloquée",
  "datePicker": {
    "ddmmyyyy": "jj/mm/aaaa",
    "ariaLabel": "Utilisez les touches flèches du clavier pour choisir une date",
    "previousMonth": "Mois précédent",
    "nextMonth": "Mois suivant",
    "months": "Janvier,Février,Mars,Avril,Mai,Juin,Juillet,Août,Septembre,Octobre,Novembre,Décembre",
    "weekdays": "Dimanche,Lundi,Mardi,Mercredi,Jeudi,Vendredi,Samedi",
    "weekdaysShort": "Dim,Lun,Mar,Mer,Jeu,Ven,Sam"
  },
  "datetime": {
    "label": "Définir la date et l'heure"
  },
  "see": "Prévisualiser",
  "download": "Télécharger",
  "delete": "Supprimer",
  "logo": {
    "addLogo": "Glissez votre logo ici ou cliquez pour parcourir"
  },
  "confirmation": {
    "question": "Veuillez confirmer cette action",
    "yes": "Oui, je confirme",
    "no": "Non"
  },
  "deleteConfirmation": {
    "question": "Êtes-vous sûr de vouloir le supprimer ?",
    "yes": "Oui, je veux supprimer",
    "no": "Non"
  },
  "deleteCdcConfirmation": {
    "question": "Cette modification va effacer les informations saisies concernant les cahiers des charges liés aux études. Voulez vous continuer ?",
    "yes": "Confirmer",
    "no": "Annuler"
  },
  "deleteDispositifConfirmation": {
    "question": "Confirmez-vous la suppression de ce dispositif ? Une fois supprimé, il ne sera plus récupérable."
  },
  "submitConfirmation": {
    "question": "Vous êtes sur le point de passer ce dispositif en attente de validation manager. Confirmez-vous cette action ? Vous pourrez ensuite informer votre manager qu'il peut procéder à la validation définitive du dispositif.",
    "yes": "Confirmer",
    "no": "Annuler"
  },
  "submitRajDafDajConfirmation": {
    "question": "Vous êtes sur le point de passer ce dispositif en attente de validation RAJ DAF/DAJ. Confirmez-vous cette action ? Selon la typologie de votre dispositif, vous pourrez ensuite informer votre RAJ ou la DAF/DAJ que celui-ci est prêt pour vérification juridique et financière.",
    "yes": "Confirmer",
    "no": "Annuler"
  },
  "validateRajDafDajConfirmation": {
    "question": "Vous êtes sur le point de valider ce dispositif. Confirmez-vous cette action ?",
    "yes": "Confirmer",
    "no": "Annuler"
  },
  "validateConfirmation": {
    "question": "Vous êtes sur le point de valider ce dispositif. Confirmez-vous cette action ?",
    "yes": "Confirmer",
    "no": "Annuler"
  },
  "publishConfirmation": {
    "question": "Vous êtes sur le point de publier ce dispositif sur AGIR, assurez vous que toutes les informations sont correctes puis confirmez.",
    "yes": "Confirmer",
    "no": "Annuler"
  },
  "unpublishConfirmation": {
    "question": "Vous êtes sur le point de dépublier ce dispositif sur AGIR. Souhaitez-vous continuer ?",
    "yes": "Dépublier",
    "no": "Annuler"
  },
  "PostProgram": {
    "question": "Ce programme est déjà présent, souhaitez-vous l'utiliser ?",
    "yes": "Oui",
    "no": "Non"
  },
  "PostPartenaire": {
    "question": "Ce partenaire est déjà présent, souhaitez-vous l'utiliser ?",
    "yes": "Oui",
    "no": "Non"
  },
  "search": {
    "placeholder": "Rechercher",
    "noDataFound": "Il n'y a aucun résultat correspondant à votre recherche."
  },
  "ged": {
    "addDocument": "Ajouter un document",
    "importDocument": "Valider l'import du document",
    "updateDocument": "Modifier le document",
    "addFile": "Glissez votre document ici ou cliquez pour parcourir",
    "undo": "Annuler",
    "nbMaxReached": "Nombre maximum de documents ajoutés atteint",
    "search": {
      "label": "Rechercher un fichier existant",
      "docName": "Nom du fichier",
      "docTitle": "Titre du document",
      "docType": "Type",
      "noDataFound": "Il n'y a aucun fichier correspondant à votre recherche veuillez modifier votre saisie ou ajouter un nouveau fichier."
    },
    "labels": {
      "titleFile": "Titre du document",
      "typeFile": "Type de document",
      "typeFilePlaceholder": "Sélectionnez un type de document"
    },
    "popup": {
      "duplicatedName": {
        "question": "Un document avec le même nom de fichier est déjà présent dans la base documentaire R2DA. Souhaitez-vous l'utiliser ? Si non, vous devez modifier le nom de votre fichier pour pouvoir l'ajouter.",
        "yes": "Oui",
        "no": "Non"
      },
      "warningUpdate": {
        "question": "Attention, la mise à jour d'un fichier est appliquée à tous les dispositifs l'utilisant. Confirmez-vous l'action ?",
        "yes": "Oui",
        "no": "Non"
      }
    }
  },
  "fileTable": {
    "author": "Par",
    "createdAt": "Ajouté le",
    "updatedAt": "Modifié le",
    "name": "Nom",
    "noData": "Aucune pièce jointe ajoutée",
    "title": "Titre du document",
    "type": "Type de document",
    "typeStructure": "Type de structure",
    "typeProject": "Type de projet"
  },
  "fileUpload": {
    "tooBig": "Le poids maximal autorisé est de {maxSize}",
    "changeFile": "Réessayer en glissant un autre fichier ou cliquez pour parcourir",
    "sent": "Fichier envoyé",
    "current": "Fichier actuel",
    "currentDeleted": "Ce fichier sera supprimé",
    "addFile": "Glissez votre fichier ici ou cliquez pour parcourir"
  },
  "edit": "Modifier",
  "editDatas": "Modifier les informations",
  "form": {
    "error": {
      "email": "L'adresse email doit être valide.",
      "format": "Les caractères spéciaux ne sont pas autorisés.",
      "alpha": "Seuls les caractères alphabétiques sont autorisés.",
      "aapAcronymeFormat": "Seuls les caractères alphabétiques, les caractères numériques, les espaces et les tirets sont autorisés.",
      "maxValue": "La valeur est trop grande.",
      "minValue": "La valeur est trop petite.",
      "helpTextMinChar": "Merci de saisir un texte d'aide de minimum {min} caractères. Si vous n'en avez pas, laissez ce champ vide.",
      "minChar": "Le champ doit contenir au minimum {min} caractères.",
      "maxChar": "Le champ est limité à {max} caractères.",
      "maxLength": "La longueur est trop grande.",
      "minLength": "La longueur est trop petite.",
      "requiredFields": "Les champs sont requis.",
      "required": "Le champ est requis.",
      "noFile": "Aucun fichier sélectionné",
      "compareDatesGte": "La date de fin doit être supérieure ou égale à la date de début"
    },
    "clearSelection": "Supprimer la selection",
    "betweenCharacters": "(Entre {min} et {max} caractères)",
    "minCharacters": "(Min. {min} caractères)",
    "maxCharacters": "(Max. {max} caractères)"
  },
  "cacheClear": "Vider le cache serveur",
  "help": {
    "title": "Besoin d'aide\u00a0?",
    "display": "Afficher la page d'aide",
    "doc": "Formation et documentation",
    "assist": "Assistance utilisateur",
    "close": "Fermer l'aide",
    "campusLogoAlt": "Logo campus ADEME",
    "AssistLogoAlt": "Icone assistant téléphonique",
    "campusTxt": "<p class=\"mb-2\">Le support de formation est en ligne sur la page <abbr v-tooltip data-tooltip-content=\"Référentiel des aides ADEME\">R2DA</abbr> de l'intrADEME.</p> <p class=\"fs-4 pt-3 text-end\"> <i class=\"material-icons fs-3 text-primary\" aria-hidden=\"true\">layers</i> <a class=\"link\" rel=\"noopener noreferrer\" href=\"https://collaboratif.ademe.fr/jcms/prod_1000121826/fr/-processus-subvention-r2da-redaction-et-publication-des-dispositifs-gre-a-gre-et-des-appels-a-projets-aap\" target=\"_blank\"> Lien vers la page intrADEME</a> </p> ",
    "assistTxt": "<p class=\"mb-2\">Avant de faire une demande d'assistance, merci de consulter le support de formation. </p> <p class=\"mb-2\"> Pour vos demandes d'assistance, allez sur la nouvelle plateforme de support <a class=\"link\" rel=\"noopener noreferrer\" href=\"https://support.ademe.intra/\" target=\"_blank\">https://support.ademe.intra</a> (identifiants : email + mot de passe Windows) où vous trouverez des formulaires pré-formatés simples à compléter, et qui garantissent à l'assistance d'avoir les éléments nécessaires à l'analyse de votre demande. Votre demande ne doit porter que sur un seul sujet ou un seul dossier. Les demandes multiples doivent faire l'objet de demandes distinctes. </p> <p> A noter, vos questions métier sont à adresser selon le cas\u00a0: </p> <ul class=\"mb-2\"></ul> <li>À votre référent métier</li><li>À la DAJ (ancien SAJ)\u00a0: <a href=\"mailto:saj.aides{'@'}ademe.fr\" class=\"link fw-3\">saj.aides{'@'}ademe.fr</a></li> <li>À votre RAJ</li></ul> <p class=\"fs-4 pt-3 text-end\"> <i class=\"material-icons fs-3 text-primary\" aria-hidden=\"true\">layers</i> <a class=\"link\" rel=\"noopener noreferrer\" href=\"https://support.ademe.intra/home\" target=\"_blank\"> Lien vers la plateforme support assistance utilisateur</a> </p> ",
    "show": "Afficher l'aide",
    "hide": "Cacher l'aide"
  },
  "tooltip": {
    "show": "Afficher les informations",
    "hide": "Cacher les informations"
  },
  "app": {
    "title": "Référentiel des aides\u00a0<abbr v-tooltip data-tooltip-content=\"Agence de l'Environnement et de la Maîtrise de l'Énergie\">ADEME</abbr>"
  },
  "menu": {
    "backHome": "Accueil",
    "back": "Page précédente",
    "copyBearer": "Copier le bearer token",
    "expandRightPanel": "Agrandir le menu",
    "hidePanel": "Cacher le menu",
    "infos": "Informations",
    "logout": "Déconnexion",
    "reduceRightPanel": "Réduire le menu",
    "showPanel": "Afficher le menu",
    "ref": "Gestion des référentiels",
    "users": "Gestion des utilisateurs",
    "projects": "Liste des dispositifs",
    "documents": "Gestion des documents"
  },
  "nextStep": "Passer à l'étape suivante",
  "noResult": "Aucun résultat",
  "previousStep": "Revenir à l'étape précédente",
  "noData": "Aucune donnée",
  "notSpecified": "Non renseigné",
  "filters": "Filtres de recherche",
  "project": {
    "duplicate": "Dupliquer",
    "duplicateConfirm": "Vous êtes sur le point de dupliquer ce dispositif. Voulez-vous continuer ?",
    "duplicateTitle": "Duplication du dispositif {id} en cours...",
    "type": {
      "aap": "Appel à projet",
      "gag": "Gré à gré"
    },
    "projectList": {
      "title": "Liste des dispositifs",
      "addProjectBtn": "Ajouter un dispositif",
      "actions": {
        "uploadResults": "Déposer les résultats"
      },
      "placeholder": {
        "search": "Rechercher dans les dispositifs",
        "searchAuthor": "Rechercher..."
      },
      "label": {
        "personalization": "Personnalisation des résultats",
        "type": "Type de dispositif",
        "status": "État",
        "dateStatus": "Statut",
        "theme": "Thèmes et sous-thèmes"
      }
    },
    "author": {
      "all": "Toutes les aides",
      "me": "Mes aides uniquement"
    },
    "status": {
      "brouillon": "Brouillon",
      "soumis": "À valider manager",
      "public": "Publié",
      "valide": "Validé manager",
      "soumis_raj_daf_daj": "À valider RAJ DAF/DAJ",
      "valide_raj_daf_daj": "Validé RAJ DAF/DAJ"
    },
    "dateStatus": {
      "opened": "Ouvert",
      "closed": "Clos"
    },
    "projectPreview": {
      "generalInformations": "Données générales",
      "depositHelp": "Aide au dépôt",
      "eligibility": "Éligibilité",
      "studyHelps": "Aide aux études - Cahier des charges",
      "internalInformations": "Données internes",
      "tags": "Étiquettes",
      "documentList": "Pièces à déposer",
      "title": "Éditer le dispositif {idFonctionnel}",
      "titleExt": "Dispositif {idFonctionnel}",
      "duplicate": "Dupliquer le dispositif",
      "delete": "Supprimer le dispositif",
      "documentLists": "Listes de pièces",
      "validate": "Validation manager",
      "publish": "Publier le dispositif",
      "unpublish": "Dépublier le dispositif",
      "submit": "Prêt pour validation manager",
      "submit_raj_daf_daj": "Prêt pour validation RAJ DAF/DAJ",
      "validate_raj_daf_daj": "Validation RAJ DAF/DAJ",
      "defaultErrors": "Erreurs hors sections",
      "historic": "Historique",
      "seeHistoric": "Voir tout l'historique"
    },
    "newProject": {
      "title": "Créer un nouveau dispositif d'aide",
      "firstStep": "ÉTAPE 1",
      "secondStep": "ÉTAPE 2",
      "thirdStep": "ÉTAPE 3",
      "createProject": "Créer le dispositif"
    },
    "firstname": "Prénom",
    "lastname": "Nom",
    "email": "Email",
    "generalInformations": {
      "aap": {
        "acronym": "Acronyme du dispositif",
        "openingDate": "Date d'ouverture de dépot",
        "closingDate": "Date de clôture de dépot",
        "projectSubType": "Sous type d'appel",
        "contactEmail": "Email de contact",
        "resultsDate": "Date des résultats",
        "courrielContact": "Email responsable de l'<abbr v-tooltip data-tooltip-content=\"Appel À Projet\">AAP</abbr>",
        "previewResultsDate": "Délai du dépôt des dossiers"
      },
      "creditThumbnail": "Crédits",
      "description": "Description détaillée du dispositif",
      "endDate": "Date de clôture du dispositif",
      "endDateHelp": "Afin que l'assistance puisse accompagner les porteurs de projet s'ils rencontrent des difficultés lors du dépôt de leur demande, renseignez un jour et une heure de fin compatibles. L'assistance ADEME est disponible du lundi au vendredi de 8h30 à 17h30.",
      "gag": {
        "millesime": "Millésime"
      },
      "geographicArea": "Zones géographiques du dispositif",
      "geographicCoverage": "Couverture géographique",
      "helpType": "Type de dispositif",
      "partners": "En partenariat avec",
      "projectTarget": "À qui l'aide s'adresse-t-elle ?",
      "projectType": "Type de projet",
      "projectTypeQuoteMessage": "La sélection du type de projet {quoteTypes} implique pour les porteurs l'ajout d'un devis et d'une proposition technique détaillée. Si vous ne souhaitez pas soumettre les porteurs de projet à cette contrainte, sélectionnez simplement le type de projet {fallbackType}.",
      "smallDescription": "Description courte du dispositif",
      "startDate": "Date d'ouverture du dispositif",
      "target": "Cibles",
      "targetDetails": "Précisez la cible \"À qui s'adresse l'aide ?\"",
      "theme": "Thèmes",
      "title": "Titre du dispositif",
      "unspecifiedDates": "Dates d'ouverture/clôture non renseignées"
    },
    "partners": {
      "labels": {
        "logoTitle": "Logo du partenaire",
        "editPartner": "Créer ou modifier un partenaire",
        "searchParnter": "Ajouter un partenaire existant",
        "searchPlaceholder": "Rechercher un partenaire",
        "noDataFound": "Pas de partenaire trouvé",
        "selectZoneGeoPartner": "Ces partenaires sont-ils spécifiques à une région ?",
        "selectedPartners": "Partenaires associés au dispositif",
        "title": "Partenaires"
      },
      "title": "Partenaires"
    },
    "specifications": "Cahier des charges {idCdc}",
    "eligibility": {
      "title": "Vérifier votre éligibilité",
      "more": "Texte supplémentaire"
    },
    "prepareFile": {
      "cdc": {
        "btnAdd": "Ajouter un type de cahier des charges",
        "firstPoint": "Consultez les bureaux d'études sur la base du ou des cahiers des charges suivant :",
        "precision": "Précisez la cible de ce cahier des charges",
        "secondPoint": "Présélectionnez une offre. Attention à ce stade, ne vous engagez pas et ne signez pas de commande.",
        "thirdPoint": "Déposez une demande d'aide en joignant la proposition technique et financière du bureau d'études.",
        "noData": "Aucun cahier des charges renseigné."
      },
      "depositHelp": {
        "title": "Pour vous aider dans vos démarches",
        "text": "<b>L'opération pour laquelle vous sollicitez une aide financière ne doit pas avoir commencé ou ne doit pas avoir donné lieu à des engagements fermes</b> (sous quelque forme que ce soit : marché signé, commande signée, devis accepté…).",
        "otherDocumentsTitle": "Ajouter des documents supplémentaires"
      },
      "title": "Préparer votre dossier",
      "stepsToFollow": {
        "title": "Les étapes à suivre"
      },
      "partsToDespose": {
        "title": "Pièces à déposer"
      }
    },
    "thumbnail": {
      "labels": {
        "alt": "Information descriptive (attribut alt)",
        "credit": "Crédits visuel",
        "title": "Titre du visuel"
      },
      "add": "Glissez votre image ici ou cliquez pour parcourir",
      "title": "Illustration du dispositif"
    },
    "internalData": {
      "labels": {
        "acronym": "Acronyme",
        "UGQuestion": "Gestion centralisée",
        "uniteDeGestion": {
          "pilote": "<abbr v-tooltip data-tooltip-content=\"Unité de Gestion\">UG</abbr> Pilote",
          "simple": "<abbr v-tooltip data-tooltip-content=\"Unité de Gestion\">UG</abbr>",
          "noData": "aucune <abbr v-tooltip data-tooltip-content=\"Unité de Gestion\">UG</abbr>"
        },
        "urlSitePartenaire": "Url site partenaire",
        "referent": "Référent thématique|Référents thématiques",
        "coordinator": "Coordinateur du dispositif",
        "manager": "Responsable de validation",
        "coordinateurNotReferent": "Aucun des référents thématiques n'est coordinateur du dispositif",
        "accuseReceptionEmail": "Adresse mail à mettre en copie de l'<abbr v-tooltip data-tooltip-content=\"Accusé de Réception\">A/R</abbr> de la demande d'aide | Adresse mail à mettre en copie de l'<abbr v-tooltip data-tooltip-content=\"Accusé de Réception\">A/R</abbr> de la demande d'aide | Adresses mail à mettre en copie de l'<abbr v-tooltip data-tooltip-content=\"Accusé de Réception\">A/R</abbr> de la demande d'aide",
        "typeDepot": "Site de dépôt des demandes/candidatures",
        "programme": {
          "title": "Programme <abbr v-tooltip data-tooltip-content=\"Appel À Projet\">AAP</abbr>",
          "acronyme": "Saisir l'acronyme",
          "acronymeTitle": "Acronyme du programme",
          "logoTitle": "Logo",
          "acronymeDesc": "Commencer à saisir l'acronyme du programme pour rechercher parmi les programmes existants ou ajoutez un nouveau programme",
          "searchPlaceholder": "Rechercher un programme",
          "add": "Créer un nouveau programme ?",
          "edit": "Modifier le programme",
          "noDataFound": "Pas de programme trouvé"
        },
        "spentList": {
          "title": "Aide à l'instruction des dossiers",
          "selectLabel": "Choisir des catégories et/ou listes de dépenses"
        },
        "preWrittenText": {
          "title": "Textes à afficher dans les demandes d'aide",
          "shortDescription": "Description courte du projet",
          "description": "Description du projet",
          "context": "Contexte du projet",
          "objectifs": "Enjeux et objectifs",
          "rapportFinal": "Détails du rapport final"
        },
        "budgetaryIdentification": {
          "title": "Caractérisation budgétaire et technique",
          "modeGestionComptable": {
            "title": "Mode de gestion comptable",
            "AECP": "Gestion AE/CP",
            "IA": "France 2030 - PIA 1,2,3,4",
            "CT": "Gestion en compte de tiers"
          },
          "sousProgrammeBudgetaire": {
            "label": "Programme - Sous-programme budgétaire",
            "warning": "La saisie d'un mode de gestion comptable est obligatoire pour pouvoir renseigner un programme budgétaire."
          },
          "origineFonds": {
            "label": "Origine des fonds",
            "warning": "La saisie d'un mode de gestion comptable est obligatoire pour pouvoir renseigner une origine des fonds."
          }
        },
        "lisa": {
          "title": "Nature lisa",
          "activity": "Sous-activité",
          "aidSystem": "Système d'aide",
          "thematic": "Thématique",
          "noValue": "Aucune valeur ne peut être associée"
        },
        "resultsAAP": "Fichier des résultats <abbr v-tooltip data-tooltip-content=\"Appel À Projet\">AAP</abbr>",
        "programmeAAP": "Programme <abbr v-tooltip data-tooltip-content=\"Appel À Projet\">AAP</abbr>",
        "appelConjoint": "Est-ce un appel conjoint ?",
        "leader": "L'<abbr v-tooltip data-tooltip-content=\"Agence de l'Environnement et de la Maîtrise de l'Énergie\">ADEME</abbr> est-elle leader ?"
      }
    },
    "tags": {
      "labels": {
        "funds": "Fonds",
        "finance": "Origine du financement"
      },
      "title": "Étiquettes"
    },
    "trackingInformations": {
      "labels": {
        "choiceManager": "Désigner un coordinateur du dispositif parmi les référents",
        "manager": "Coordinateur du dispositif",
        "projectTarget": "Cible du projet",
        "projectType": "Type de projet",
        "referent": "Référent thématique|Référents thématiques",
        "email": "Email",
        "firstName": "Prénom",
        "lastName": "Nom",
        "accuseReceptionEmail": "Adresse mail à mettre en copie de l'<abbr v-tooltip data-tooltip-content=\"Accusé de Réception\">A/R</abbr> de la demande d'aide | Adresses mails à mettre en copie de l'<abbr v-tooltip data-tooltip-content=\"Accusé de Réception\">A/R</abbr> de la demande d'aide"
      },
      "title": "Informations de suivi"
    },
    "documentLists": {
      "labels": {
        "uniqueDocumentList": "La liste des pièces est-elle unique pour le dispositif ?",
        "listUnique": "Liste unique",
        "listWithoutCriteria": "Liste - Critère à définir",
        "listWithCriteria": "Liste \"{criteria}\"",
        "listWithoutCriteriaLong": "Liste de pièces - Critère à définir",
        "listWithCriteriaLong": "Liste de pièces \"{criteria}\"",
        "projectTypeFile": "Cette pièce est-elle spécifique à un type de projet ?",
        "projectTypeFilePlaceholder": "Sélectionnez un type de projet",
        "legalStatusFile": "Cette pièce est-elle spécifique à un type de structure ?",
        "legalStatusFilePlaceholder": "Sélectionnez un type de structure"
      },
      "errors": {
        "listMinLength": "{min} listes sont requises au minimum."
      },
      "addListBtn": "Ajouter une autre liste de pièces",
      "title": "Liste de pièces | Listes de pièces",
      "obligatoire": "Pièce obligatoire"
    }
  },
  "partners": {
    "edit": {
      "title": "Éditer un partenaire",
      "creationCheck": "Ceci est un nouveau partenaire",
      "name": "Nom du partenaire",
      "siteUrl": "Url du site partenaire",
      "siteOtherUrl": "Autre url",
      "logoUrl": "Logo du partenaire",
      "id": "Identifiant technique",
      "addPartnerBtn": "Ajouter le partenaire",
      "createAndAdd": "Créer et ajouter",
      "update": "Modifier"
    },
    "add": "Créer un nouveau partenaire ?"
  },
  "documentList": {
    "labels": {
      "criteria": "Saisissez le critère de choix de cette liste",
      "withoutModel": "Liste des pièces sans modèle",
      "modelTitle": "Pièces avec modèle",
      "withoutModelTitle": "Pièces sans modèle",
      "withoutModelPlaceholder": "Insérez du texte pour cette liste de pièces",
      "mandatory": "Obligatoire",
      "optional": "Facultatif",
      "noData": "Aucun document joint"
    }
  },
  "helpText": {
    "depositHelp": {
      "cdc": {
        "underTitle": "Si votre dispositif est de type étude, vous pouvez ajouter des liens vers des cahiers des charges types, s'il y a plusieurs cahiers des charges selon les études, précisez avec une phrase simple et claire et ajouter le(s) cahier(s) des charges associé(s).",
        "precisionPlaceholder": "Exemple : Concernant les études préalables au déroulement du chantier"
      },
      "documentList": {
        "underTitle1": "Ici indiquez les pièces à déposer sans modèle, puis ajoutez les pièces à déposer avec modèle pour constituer la liste de pièces pour ce dispositif qui sera affichée dans la fiche AGIR et dans la demande d'aide.",
        "underTitle2": "Il est possible de créer plusieurs listes de pièces en précisant que la liste n'est pas unique (ci-dessous), cela vous permettra de créer plusieurs listes avec une explication pour aider le porteur à sélectionner dans AGIR. La liste de pièces sélectionnée sera également affichée au porteur lors de la demande d'aide.",
        "withoutModel": "Ajouter ici les pièces à déposer qui n'ont pas de modèle fourni (exemple RIB), ne mettre qu'une pièce par ligne et ajouter une ligne avec le signe +.",
        "withModel": "Ajouter ici les modèles d'annexe technique et/ou financière, modèles de document administratif ou autre. Vous pouvez ajouter le fichier ou rechercher parmi les modèles existants. Pour chaque pièce avec modèle, vous pouvez préciser s'il est valable pour un type de structure et/ou un type de projet spécifique.",
        "criteria": "Inscrivez ici de manière claire le texte qui permettra au porteur de choisir la bonne liste de pièces à déposer selon son projet.",
        "criteriaPlaceholder": "Exemple: Pour les projets d'installation thermique…"
      },
      "underTitle1": "Par défaut, les deux guides et le texte d'aide sont affichés sur AGIR.",
      "underTitle2": "Il est aussi possible d'ajouter des documents supplémentaires afin d'aider le porteur de projet."
    },
    "stepTwo": {
      "specifyTarget": "Précisez ici les cibles. (PME & sites de moins de 250 salariés des Grandes Entreprises dès lors que l'entreprise est à jour de ses obligations vis-à-vis de l'audit énergétique réglementaire). Le format gras est accepté. Ne pas mettre de titre ou grande police."
    },
    "eligibility": {
      "helpAAP": "Pour les appels à projets et autres types d'appels, vous pouvez renseigner jusqu'à 3 fichiers en lien avec le règlement et les conditions de l'<abbr v-tooltip data-tooltip-content=\"Appel À Projet\">AAP</abbr>. Il s'agit en général du Cahier des charges ou du Règlement de l'<abbr v-tooltip data-tooltip-content=\"Appel À Projet\">AAP</abbr>. Vous pouvez également intégrer tout autre fichier en lien avec l'appel.",
      "helpGAG": "Pour les dispositifs de gré à gré, 1 seul fichier est à renseigner, il s'agit des Conditions d'éligibilité et de financement (CEF).",
      "additionalText": "Exemple : Les aides sont octroyées majoritairement via les Directions régionales de l'ADEME. Un contact préalable auprès de celle concernée par le projet est conseillé avant toute démarche, afin de vous guider, de préciser les critères d'éligibilité et de vous apporter un éclairage technique. Merci d'utiliser le formulaire ci-contre « Contacter l'ADEME ».",
      "textHelp": "Mettez en forme les informations à valoriser, avec du gras ou des listes à puces. En revanche, n'ajoutez pas de niveaux de titres et ne changez pas la taille de police."
    },
    "internalData": {
      "spentList": "Vous pouvez ici sélectionner les catégories et listes de dépenses qui seront proposées automatiquement au porteur de projet dans le formulaire de demande d'aide (il pourra bien entendu en ajouter d'autres). NB: Cette donnée n'est pas encore implémentée dans OPALE - A VENIR",
      "preWrittenText": {
        "underTitle": "Afin d'aider le porteur dans la saisie de sa demande d'aide en pré remplissant directement les champs du formulaire, vous pouvez reporter ici les textes d'exemples pouvant aider le porteur de projet.",
        "finalReportPlaceholder": "Saisissez ci-dessus le détail du rapport final afin qu'il soit pré rempli dans OPALE lors de la création du justificatif « rapport final ». Ce texte sera inclus dans le contrat généré OPALE dans l'article 3 de la façon suivante : Un rapport final à remettre <délai de remise> contenant : <détails du justificatif>.",
        "genericPlaceholder": "Vous pouvez reporter ici les textes d'exemples pouvant aider le porteur de projet."
      },
      "budgetaryIdentification": {
        "underTitle": "Complétez les données budgétaires et techniques afin que celles-ci soient alimentées automatiquement dans les demandes d'aides déposées depuis ce dispositif."
      },
      "ug": "Est-ce qu'il est prévu qu'une <abbr v-tooltip data-tooltip-content=\"Unité de Gestion\">UG</abbr> en particulier instruise tous les dossiers déposés pour ce dispositif ? (Si oui les demandes d'aides seront automatiquement créées dans OPALE pour l'<abbr v-tooltip data-tooltip-content=\"Unité de Gestion\">UG</abbr> sélectionnée)",
      "coordinateur": "Personne ayant la responsabilité de coordonner/suivre la cohérence de plusieurs dispositifs reliés, soit à une origine budgétaire (exemple\u00a0:\u00a0Fonds Chaleur), soit à une direction.",
      "referent": "Personne ayant la compétence technique sur la ou les thématiques présentes dans le dispositif. Elle intervient pour créer, rédiger et suivre un dispositif d'aide d'un point de vue\u00a0:<br><ul class='ms-3'><li>technique</li><li>de l'utilisation des systèmes d'aides en vigueur.</li></ul>Elle veille à respecter et expliciter les modalités d'aides de l'agence (à savoir les conditions de financement et de paiement). Elle est la garante du contenu du dispositif permettant le dépôt d'une demande d'aide complète.",
      "manager": "Manager qui valide le dispositif une fois que celui-ci est complet et prêt à être publié (quand l'ensemble des modalités validées techniquement, financièrement et uniquement en amont sont déposées et contrôlées).",
      "accuseReceptionEmail": "Personnes qui seront en Cci de l'accusé de réception de la demande d'aide afin d'être informées et/ou de la traiter dans Opale. Les mails indiqués peuvent être externes à l'ADEME."
    },
    "thumbnail": {
      "alt": "Cette information est utile pour l'accessibilité (personne malvoyante). Mettre une description très courte de l'image.",
      "credits": "Pour utiliser une image d'illustration sur nos sites, nous devons disposer des droits adéquats. Ce sont les crédits. Penser à s'assurer auprès de Monique Coignard que les images utilisées peuvent l'être."
    },
    "tags": {
      "finance": "Sélectionner ici le ou les financements de ce dispositif. Cette information est utilisée dans les filtres de recherche AGIR."
    },
    "partners": {
      "underTitle": "Vous pouvez ici ajouter un ou plusieurs partenaires à votre dispositif. Vous pouvez rechercher un partenaire existant pour l'ajouter.",
      "url": "Ajouter ici l'url qui sera cliquable dans AGIR.",
      "otherUrl": "Ajouter ici une url d'un site en anglais le cas échéant. Cette url sera envoyée sur le site ANR."
    }
  },
  "close": "Fermer",
  "save": "Valider les informations",
  "select": {
    "choose": "Sélectionnez dans la liste",
    "none": "Aucun"
  },
  "table": {
    "name": "NOM",
    "date": "DATE",
    "author": "AJOUTÉ PAR"
  },
  "validate": "Valider",
  "input": {
    "focus": "Éditer"
  },
  "loading": "Chargement...",
  "error": "Une erreur est survenue.",
  "success": "Succès",
  "expandMore": "Etendre",
  "expandLess": "Réduire",
  "logout": "Déconnexion",
  "errorPages": {
    "noServices": "Service indisponible",
    "noServicesTxt": "Le service est momentanément indisponible. Veuillez réessayer ultérieurement.",
    "retry": "Réessayer",
    "backHome": "Retourner à l'accueil",
    "noAccess": "Accès non autorisé",
    "noAccessTxt": "Vous n'êtes pas autorisé à accéder à cette interface. Veuillez contacter les personnes en charge du référentiel des aides pour être autorisé.",
    "notFound": "Page non trouvée",
    "notFoundTxt": "Désolé, la page que vous demandez semble introuvable."
  },
  "errors": {
    "unknown": "Erreur inconnue",
    "search": "Une erreur est survenue lors de la recherche. Merci de réessayer ultérieurement...",
    "list": "Une erreur est survenue lors de la récupération des données. Merci de réessayer ultérieurement...",
    "count": "Une erreur a été remontée : | Plusieurs erreurs ont été remontées :"
  },
  "http": {
    "413": "La requête est trop volumineuse."
  },
  "results": "Aucun résultat | 1 résultat | {count} résultats",
  "historic": {
    "label": {
      "type": "Type",
      "comment": "Commentaire",
      "author": "Auteur",
      "date": "Date",
      "tableCaption": "Liste des événements liés au dispositif"
    }
  },
  "logoAlt": "Logo Référentiel, Outil Pour la gestion des Référentiels des aides ADEME",
  "sideNav": "Navigation principale",
  "referentials": {
    "list": {
      "search": "Rechercher un référentiel",
      "title": "Gestion des référentiels"
    },
    "detail": {
      "addItemBtn": "Ajouter une entrée",
      "addSubItemBtn": "Ajouter une sous-entrée",
      "saveBtn": "Valider les modifications",
      "label": {
        "dateDerniereModification": "Der. modif.",
        "author": "Auteur",
        "code": "Code",
        "title": "Libellé",
        "startDate": "Date de début",
        "endDate": "Date de fin",
        "logo": "Logo",
        "addLogo": "Ajouter un logo",
        "tableCaption": "Liste de références"
      },
      "placeholder": {
        "code": "Saisir un code",
        "title": "Saisir un libellé"
      }
    },
    "lastUpdate": "Dernière modification par {author} le {date}",
    "noUpdate": "Aucune modification apportée à ce référentiel",
    "ref": {
      "typeProjet": {
        "title": "Types de projet",
        "search": "Rechercher un type de projet"
      },
      "thematique": {
        "title": ["Thématiques", "Sous-thématiques"],
        "titleOneLine": "Thématiques et Sous-thématiques",
        "search": "Rechercher une thématique/sous-thématique",
        "addItemBtn": "Ajouter une thématique",
        "addSubItemBtn": "Ajouter une sous-thématique"
      },
      "cibleProjet": {
        "title": "Cibles",
        "search": "Rechercher une cible"
      },
      "sousType": {
        "title": "Sous types <abbr v-tooltip data-tooltip-content=\"Appel À Projet\">AAP</abbr>",
        "search": "Rechercher un sous-type AAP"
      },
      "fond": {
        "title": "Fonds",
        "search": "Rechercher un fond"
      },
      "financement": {
        "title": "Financements",
        "search": "Rechercher un financement"
      },
      "uniteGestion": {
        "title": "Unités de gestion",
        "search": "Rechercher une unité de gestion"
      },
      "listeDepense": {
        "title": ["Catégories", "Types de dépenses"],
        "titleOneLine": "Catégories et types de dépenses",
        "search": "Rechercher une catégorie ou un type de dépense",
        "addItemBtn": "Ajouter une catégorie",
        "addSubItemBtn": "Ajouter un type de dépenses"
      }
    }
  },
  "users": {
    "list": {
      "search": "Rechercher un utilisateur",
      "title": "Gestion des utilisateurs",
      "addUser": "Ajouter un utilisateur",
      "label": {
        "profile": "Profil"
      },
      "helpText": "Tous les collaborateurs <abbr v-tooltip data-tooltip-content=\"Agence de l'Environnement et de la Maîtrise de l'Énergie\">ADEME</abbr> ont accès à cette application en consultation.<br/>Sont affichées ici les personnes n'appartenant pas à l'<abbr v-tooltip data-tooltip-content=\"Agence de l'Environnement et de la Maîtrise de l'Énergie\">ADEME</abbr>."
    },
    "label": {
      "user": "Utilisateur",
      "email": "Mail",
      "profile": "Profil",
      "rights": "Droits"
    },
    "actions": {
      "edit": "Modifier",
      "remove": "Désactiver",
      "removeText": "Confirmez-vous la désactivation de l'utilisateur {user} sur le Référentiel des aides <abbr v-tooltip data-tooltip-content=\"Agence de l'Environnement et de la Maîtrise de l'Énergie\">ADEME</abbr> ?"
    },
    "profile": {
      "ROLE_ADMIN": {
        "title": "Administration",
        "description": "Ce profil permet de consulter, éditer, valider (en tant que manager ou RAJ ou DAF/DAJ), publier les dispositifs, gérer les référentiels et les utilisateurs."
      },
      "ROLE_PUBLICATION": {
        "title": "Publication",
        "description": "Ce profil permet de consulter, éditer et publier les dispositifs."
      },
      "ROLE_VALIDATION": {
        "title": "Validation manager",
        "description": "Ce profil permet de consulter, éditer et valider (en tant que manager) les dispositifs."
      },
      "ROLE_VALIDATION_RAJ_DAF_DAJ": {
        "title": "Validation RAJ DAF/DAJ",
        "description": "Ce profil permet de consulter, éditer et valider (en tant que RAJ ou DAF/DAJ) les dispositifs."
      },
      "ROLE_EDITION": {
        "title": "Edition",
        "description": "Ce profil permet de consulter et éditer les dispositifs."
      },
      "ROLE_CONSULTATION": {
        "title": "Consultation",
        "description": "Ce profil permet de consulter les dispositifs."
      },
      "ROLE_EXTERNAL_CONSULTATION": {
        "title": "Consultation externe",
        "description": "Ce profil permet de consulter les dispositifs publiés uniquement. Les autres pages et fonctionnalités ne sont pas accessibles."
      }
    },
    "edit": {
      "title": "Chargement...",
      "label": {
        "roles": "Profils",
        "email": "Email"
      }
    },
    "new": {
      "title": "Ajouter un utilisateur"
    },
    "deletion": {
      "title": "Désactivation de l'utilisateur : {user}"
    }
  },
  "documents": {
    "list": {
      "search": "Rechercher un document",
      "title": "Gestion des documents",
      "addDocument": "Ajouter un document",
      "label": {
        "type": "Type de document"
      }
    },
    "label": {
      "type": "Type",
      "file": "Fichier",
      "filename": "Nom du fichier",
      "info": "Info.",
      "statutJuridique": "Structure",
      "typeProjet": "Projet"
    },
    "actions": {
      "edit": "Modifier"
    },
    "edit": {
      "title": "Chargement...",
      "titleLoaded": "Modification du fichier \"{title}\"",
      "label": {
        "legalStatusFile": "Type de structure",
        "projectTypeFile": "Type de projet"
      }
    },
    "new": {
      "title": "Ajouter un document"
    }
  },
  "display": {
    "table": "Affichage en liste",
    "card": "Affichage en grille"
  },
  "preview": {
    "standard": "Prévisualisation standard",
    "agir": "Prévisualisation Agir",
    "title": {
      "target": "Êtes-vous concerné ?",
      "details": "Le détail de l'aide",
      "prepareFile": "Préparez votre dossier",
      "depositFile": "Déposez votre dossier",
      "info": "Informations utiles",
      "geographicArea": "Quel(les) région(s) ou pays proposent ce dispositif ?",
      "eligibility": "Je vérifie mon éligibilité",
      "depositHelp": "1. Téléchargez tous les documents utiles pour vous accompagner dans vos démarches :",
      "listDocument": "2. Rassemblez l'ensemble des documents",
      "calendar": "Calendrier",
      "contact": "Contactez-nous",
      "aapResults": "Liste des lauréats"
    },
    "text": {
      "cdc": "Je consulte les bureaux d'études sur la base du ou des cahiers des charges",
      "labelCdc": "Cahier des charges :",
      "contact": "Vous avez consulté l'ensemble de la page et vous avez besoin d'informations supplémentaires sur ce dispositif.",
      "listDocument": "En fonction de votre projet, différents documents peuvent vous être demandé.",
      "startDate": "À partir du : ",
      "endDate": "Jusqu'au : ",
      "aapResults": "L'analyse des dossiers est en cours. La liste des lauréats sera disponible prochainement."
    },
    "button": {
      "depositFile": "Connectez-vous pour déposer un dossier",
      "contact": "Je contacte l' <abbr v-tooltip data-tooltip-content=\"Agence de l'Environnement et de la Maîtrise de l'Énergie\">ADEME</abbr>"
    }
  }
}
